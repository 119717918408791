"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, FormControl } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { MultiUserSelect } from "@/v2/components/forms/user-select/multi-user-select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  KeyResultOwnerType,
  keyResultOwnerTypeOptions,
  KeyResultProgressType,
  keyResultProgressTypeOptions,
  KeyResultStatus,
  keyResultStatusOptions
} from "@/v2/feature/growth/goals/features/key-results/key-result.interface";
import { KeyResultAPI } from "@/v2/feature/growth/goals/features/key-results/key-results.api";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const KeyResultMutationModal = ({
  keyResult,
  goalId,
  isOpen,
  mode,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    KeyResultCreationModalContent,
    {
      goalId,
      keyResult,
      onClose,
      refresh,
      mode
    }
  ) });
};
const KeyResultCreationModalContent = ({
  mode,
  goalId,
  keyResult,
  onClose,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  const isCreateMode = mode === "create";
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { cachedUsers } = useCachedUsers({ refresh: true });
  const { data: departments } = useApiClient(DepartmentEndpoints.getCompanyDepartmentsOptions(), {
    suspense: false,
    dedupingInterval: 3e4
  });
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false,
    dedupingInterval: 3e4
  });
  const formik = useFormik({
    initialValues: {
      name: (_a = keyResult == null ? void 0 : keyResult.name) != null ? _a : "",
      description: (_b = keyResult == null ? void 0 : keyResult.description) != null ? _b : "",
      dueDate: (_c = keyResult == null ? void 0 : keyResult.dueDate) != null ? _c : null,
      ownerType: (_d = keyResult == null ? void 0 : keyResult.ownerType) != null ? _d : KeyResultOwnerType.Individual,
      ownerIds: (_e = keyResult == null ? void 0 : keyResult.ownerIds) != null ? _e : [],
      factorIds: (_f = keyResult == null ? void 0 : keyResult.factorIds) != null ? _f : [],
      departmentId: (_g = keyResult == null ? void 0 : keyResult.departmentId) != null ? _g : null,
      progressType: (_h = keyResult == null ? void 0 : keyResult.progressType) != null ? _h : KeyResultProgressType.Percentage,
      progressTarget: (_i = keyResult == null ? void 0 : keyResult.progressTarget) != null ? _i : 0,
      status: (_j = keyResult == null ? void 0 : keyResult.status) != null ? _j : KeyResultStatus.Planned
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t("KeyResultDrawer.errorMessages.nameRequired")),
      description: yup.string().notRequired(),
      ownerType: yup.string().required(polyglot.t("KeyResultDrawer.errorMessages.ownerTypeRequired")),
      status: yup.string().notRequired(),
      ownerIds: yup.array().required(polyglot.t("KeyResultDrawer.errorMessages.ownerTypeRequired")).when("ownerType", {
        is: KeyResultOwnerType.Individual,
        then: (field) => field.min(1, "KeyResultDrawer.errorMessages.keyResultMustHaveOwner").required(),
        otherwise: (field) => field
      }),
      departmentId: yup.string().nullable().optional().when("ownerType", {
        is: KeyResultOwnerType.Department,
        then: (field) => field.required(polyglot.t("KeyResultDrawer.errorMessages.departmentRequired")),
        otherwise: (field) => field.optional()
      })
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (isCreateMode) {
          yield KeyResultAPI.createKeyResult(goalId, __spreadValues({}, values));
        } else {
          if (!keyResult) throw new Error("Updated key result is not defined");
          yield KeyResultAPI.patchKeyResult(goalId, keyResult.id, __spreadValues({}, values));
        }
        yield refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  const selectableUsers = useMemo(() => {
    return cachedUsers.map((u) => __spreadValues({ value: u.userId, label: u.displayName }, u));
  }, [cachedUsers]);
  const multiUserSelectValue = useMemo(() => {
    return formik.values.ownerIds.map((id) => {
      var _a2;
      const user = cachedUsers.find((u) => u.userId === id);
      return { value: id, label: (_a2 = user == null ? void 0 : user.displayName) != null ? _a2 : "" };
    });
  }, [cachedUsers, formik.values.ownerIds]);
  const selectableDepartmentOptions = useMemo(() => {
    if (!departments) return [];
    return departments.map((d) => ({ value: d.id, label: d.name }));
  }, [departments]);
  const selectableGrowthFactors = useMemo(() => {
    if (!allGrowthFactors) return [];
    return allGrowthFactors.map((factor) => {
      return {
        value: factor.id,
        label: factor.name,
        circleColor: factor.color
      };
    });
  }, [allGrowthFactors]);
  const growthFactorInputValue = useMemo(() => {
    return formik.values.factorIds.map((id) => {
      var _a2;
      const factor = selectableGrowthFactors.find((f) => f.value === id.toString());
      return { value: id, label: (_a2 = factor == null ? void 0 : factor.label) != null ? _a2 : "", circleColor: factor == null ? void 0 : factor.circleColor };
    });
  }, [formik.values.factorIds, selectableGrowthFactors]);
  const updateOwnerType = useCallback(
    (ownerType) => {
      formik.setFieldValue("ownerType", ownerType);
      if (ownerType === KeyResultOwnerType.Individual) {
        formik.setFieldValue("ownerIds", []);
      }
    },
    [formik]
  );
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t(isCreateMode ? "KeyResultDrawer.newKeyResult" : "KeyResultDrawer.editKeyResult") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("KeyResultDrawer.formFields.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        requiredLabel: true,
        error: formik.touched.name && !!formik.errors.name,
        helperText: formik.touched.name && formik.errors.name,
        endAdornment: "none",
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsx(
      RichTextField,
      {
        label: polyglot.t("KeyResultDrawer.formFields.description"),
        value: (_k = formik.values.description) != null ? _k : "",
        onChange: (value) => formik.setFieldValue("description", value),
        error: formik.touched.description && !!formik.errors.description,
        helperText: formik.touched.description && formik.errors.description
      }
    ),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_l = formik.values.dueDate) != null ? _l : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("dueDate", value);
          }
        },
        name: "dueDate",
        required: false,
        label: polyglot.t("KeyResultDrawer.formFields.dueDate"),
        error: !!formik.errors.dueDate && formik.touched.dueDate,
        helperText: formik.errors.dueDate && formik.touched.dueDate
      }
    ) }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "ownerType",
        label: polyglot.t("KeyResultDrawer.formFields.keyResultBelongsTo"),
        options: keyResultOwnerTypeOptions,
        value: formik.values.ownerType,
        onChange: (val) => updateOwnerType(val.target.value),
        requiredLabel: true,
        compareValue: formik.values.ownerType,
        error: !!formik.errors.ownerType && formik.touched.ownerType,
        helperText: formik.touched.ownerType && formik.errors.ownerType
      }
    ),
    formik.values.ownerType === KeyResultOwnerType.Individual && /* @__PURE__ */ jsx(
      MultiUserSelect,
      {
        id: "ownerIds",
        limitTags: 5,
        options: selectableUsers,
        value: multiUserSelectValue,
        onChange: (_, values) => {
          formik.setFieldValue(
            "ownerIds",
            values.map((v) => Number(v.value))
          );
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label,
        label: polyglot.t("KeyResultDrawer.formFields.select"),
        error: !!formik.errors.ownerIds && formik.touched.ownerIds,
        requiredLabel: true
      }
    ),
    formik.values.ownerType === KeyResultOwnerType.Department && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "departmentId",
        label: polyglot.t("KeyResultDrawer.formFields.select"),
        options: selectableDepartmentOptions,
        value: formik.values.departmentId,
        onChange: formik.handleChange,
        compareValue: formik.values.departmentId,
        error: !!formik.errors.departmentId && formik.touched.departmentId,
        helperText: formik.touched.departmentId && formik.errors.departmentId,
        requiredLabel: true
      }
    ),
    /* @__PURE__ */ jsx(
      MultipleSelectCheckbox,
      {
        id: "factorIds",
        label: polyglot.t("KeyResultDrawer.formFields.factors"),
        options: selectableGrowthFactors,
        value: growthFactorInputValue,
        onChange: (_, values) => {
          const growthFactorIds = values.map(({ value }) => value);
          formik.setFieldValue("factorIds", growthFactorIds, true);
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label,
        renderOptions: "chips",
        disableClearable: true
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "progressType",
        label: polyglot.t("KeyResultDrawer.formFields.progressMeasure"),
        options: keyResultProgressTypeOptions,
        value: formik.values.progressType,
        onChange: formik.handleChange,
        compareValue: formik.values.progressType,
        error: !!formik.errors.progressType && formik.touched.progressType,
        helperText: formik.touched.progressType && formik.errors.progressType
      }
    ),
    formik.values.progressType === KeyResultProgressType.Number ? /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "progressTarget",
        required: true,
        type: "number",
        label: polyglot.t("KeyResultDrawer.formFields.progressTarget"),
        value: formik.values.progressTarget,
        onChange: formik.handleChange
      }
    ) : null,
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "status",
        label: polyglot.t("KeyResultDrawer.formFields.status"),
        options: keyResultStatusOptions,
        value: formik.values.status,
        onChange: formik.handleChange,
        compareValue: formik.values.status,
        error: !!formik.errors.status && formik.touched.status,
        helperText: formik.touched.status && formik.errors.status
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("KeyResultDrawer.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
