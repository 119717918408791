"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { keyBy } from "lodash";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { GrowthChip } from "@/v2/feature/growth/shared/components/growth-chip.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
const maxChips = 4;
export const GrowthFactorsGroup = ({ factorIds }) => {
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false,
    dedupingInterval: 3e4
  });
  const growthFactorsMap = useMemo(() => {
    return keyBy(allGrowthFactors, "id");
  }, [allGrowthFactors]);
  const renderedChips = useMemo(() => {
    const factorsToRender = factorIds.slice(0, maxChips);
    return factorsToRender.map((factorId) => {
      const factor = growthFactorsMap[factorId];
      return factor ? /* @__PURE__ */ jsx(
        GrowthChip,
        {
          options: {
            border: "middleGrey2",
            text: factor.name,
            icon: factor.color
          }
        },
        factor.id
      ) : null;
    });
  }, [factorIds, growthFactorsMap]);
  const remainingChips = useMemo(() => {
    return factorIds.slice(maxChips);
  }, [factorIds]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    renderedChips,
    remainingChips.length > 0 ? /* @__PURE__ */ jsx(
      StyledTooltip,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: remainingChips.map((factorId) => {
          const factor = growthFactorsMap[factorId];
          return factor ? /* @__PURE__ */ jsxs(Fragment, { children: [
            factor.name,
            /* @__PURE__ */ jsx("br", {})
          ] }) : null;
        }) }),
        placement: "top",
        children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          GrowthChip,
          {
            options: {
              border: "middleGrey2",
              text: `+${remainingChips.length}`
            }
          }
        ) })
      }
    ) : null
  ] });
};
