"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { ListItem } from "@/v2/feature/growth/goals/components/list-item.component";
import { OwnerChip } from "@/v2/feature/growth/goals/components/owner-chip.component";
import { KeyResultProgressAPI } from "@/v2/feature/growth/goals/features/key-result-progress/key-result-progress.api";
import { GoalOwnerType } from "@/v2/feature/growth/goals/goal.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: 14, height: 14 };
export const KeyResultProgressListItem = ({ progress, goalId, onProgressDeleted }) => {
  const { polyglot } = usePolyglot();
  const [isDeleteProgressDrawerOpen, setIsDeleteProgressDrawerOpen] = useState(false);
  const getProgressActionsOptions = useCallback(
    () => [
      {
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => setIsDeleteProgressDrawerOpen(true),
        label: polyglot.t("KeyResultProgressItem.actionsMenu.delete"),
        disabled: false
      }
    ],
    [polyglot]
  );
  const deleteProgressItem = useCallback(() => __async(void 0, null, function* () {
    yield KeyResultProgressAPI.removeKeyResultProgress(goalId, progress.keyResultId, progress.id);
    onProgressDeleted == null ? void 0 : onProgressDeleted();
    setIsDeleteProgressDrawerOpen(false);
  }), [setIsDeleteProgressDrawerOpen, goalId, progress, onProgressDeleted]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      ListItem,
      {
        details: {
          description: progress.comment,
          // TODO: fix ownerType
          chips: /* @__PURE__ */ jsx(
            OwnerChip,
            {
              ownerType: GoalOwnerType.Individual,
              ownerIds: [progress.createdBy],
              multiUserAvatar: {
                avatarNameVariant: "captionSmall2"
              }
            }
          ),
          chipsSx: { marginTop: spacing.p8 }
        },
        endSection: /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: getProgressActionsOptions(),
            actionButtonDetails: {
              type: "iconButton",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "actions",
              icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
            }
          }
        ),
        endSectionSx: { display: "flex", justifyContent: "end" },
        sx: { padding: "1rem 0" },
        hoverState: false
      },
      progress.id
    ),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("DeleteKeyResultProgressDrawer.title"),
        description: polyglot.t("DeleteKeyResultProgressDrawer.description"),
        isOpen: isDeleteProgressDrawerOpen,
        setIsOpen: setIsDeleteProgressDrawerOpen,
        loading: false,
        action: deleteProgressItem
      }
    )
  ] });
};
