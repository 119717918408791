"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, FormControl } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { GoalOwnerType, GoalStatus, goalOwnerTypeOptions, goalStatusOptions } from "../goal.interface";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { MultiUserSelect } from "@/v2/components/forms/user-select/multi-user-select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { GoalAPI } from "@/v2/feature/growth/goals/goals.api";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const GoalMutationModal = ({
  mode,
  goal,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(GoalCreationModalContent, { mode, goal, onClose, refresh, isOpen }) });
};
const GoalCreationModalContent = ({
  goal,
  mode,
  onClose,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const isCreateMode = mode === "create";
  const { polyglot } = usePolyglot();
  const { cachedUsers } = useCachedUsers({ refresh: true });
  const [showMessage] = useMessage();
  const { data: departments } = useApiClient(DepartmentEndpoints.getCompanyDepartmentsOptions(), {
    suspense: false,
    dedupingInterval: 3e4
  });
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false,
    dedupingInterval: 3e4
  });
  const formik = useFormik({
    initialValues: {
      name: (_a = goal == null ? void 0 : goal.name) != null ? _a : "",
      description: (_b = goal == null ? void 0 : goal.description) != null ? _b : "",
      dueDate: (_c = goal == null ? void 0 : goal.dueDate) != null ? _c : null,
      departmentId: (_d = goal == null ? void 0 : goal.departmentId) != null ? _d : null,
      ownerType: (_e = goal == null ? void 0 : goal.ownerType) != null ? _e : GoalOwnerType.Company,
      ownerIds: (_f = goal == null ? void 0 : goal.ownerIds) != null ? _f : [],
      factorIds: (_g = goal == null ? void 0 : goal.factorIds) != null ? _g : [],
      status: (_h = goal == null ? void 0 : goal.status) != null ? _h : GoalStatus.Planned
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t("GoalDrawer.errorMessages.nameRequired")),
      description: yup.string().notRequired(),
      ownerType: yup.string().required(polyglot.t("GoalDrawer.errorMessages.ownerTypeRequired")),
      ownerIds: yup.array().required(polyglot.t("GoalDrawer.errorMessages.ownerTypeRequired")).when("ownerType", {
        is: GoalOwnerType.Individual,
        then: (field) => field.min(1, "GoalDrawer.errorMessages.goalMustHaveOwner").required(),
        otherwise: (field) => field
      }),
      departmentId: yup.string().nullable().optional().when("ownerType", {
        is: GoalOwnerType.Department,
        then: (field) => field.required(polyglot.t("GoalDrawer.errorMessages.departmentRequired")),
        otherwise: (field) => field.optional()
      }),
      status: yup.string().required()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        let goalId;
        if (isCreateMode) {
          const createdGoal = yield GoalAPI.createGoal(__spreadValues({}, values)).catch((error) => {
            throw error;
          });
          showMessage(polyglot.t("GoalDrawer.goalCreated"), "success");
          goalId = createdGoal.id;
        } else {
          if (!goal) throw new Error("goal is missing");
          const updatedGoal = yield GoalAPI.updateGoal(goal.id, __spreadValues({}, values)).catch((error) => {
            throw error;
          });
          showMessage(polyglot.t("GoalDrawer.goalCreated"), "success");
          goalId = updatedGoal.id;
        }
        yield refresh(goalId);
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  const selectableUsers = useMemo(() => {
    return cachedUsers.map((u) => __spreadValues({ value: u.userId, label: u.displayName }, u));
  }, [cachedUsers]);
  const multiUserSelectValue = useMemo(() => {
    return formik.values.ownerIds.map((id) => {
      var _a2;
      const user = cachedUsers.find((u) => u.userId === id);
      return { value: id, label: (_a2 = user == null ? void 0 : user.displayName) != null ? _a2 : "" };
    });
  }, [cachedUsers, formik.values.ownerIds]);
  const selectableDepartmentOptions = useMemo(() => {
    if (!departments) return [];
    return departments.map((d) => ({ value: d.id, label: d.name }));
  }, [departments]);
  const selectableGrowthFactors = useMemo(() => {
    if (!allGrowthFactors) return [];
    return allGrowthFactors.map((factor) => {
      return {
        value: factor.id,
        label: factor.name,
        circleColor: factor.color
      };
    });
  }, [allGrowthFactors]);
  const growthFactorInputValue = useMemo(() => {
    return formik.values.factorIds.map((id) => {
      var _a2;
      const factor = selectableGrowthFactors.find((f) => f.value === id.toString());
      return { value: id, label: (_a2 = factor == null ? void 0 : factor.label) != null ? _a2 : "", circleColor: factor == null ? void 0 : factor.circleColor };
    });
  }, [formik.values.factorIds, selectableGrowthFactors]);
  const updateOwnerType = useCallback(
    (ownerType) => {
      formik.setFieldValue("ownerType", ownerType);
      if (ownerType === GoalOwnerType.Individual) {
        formik.setFieldValue("ownerIds", []);
      }
    },
    [formik]
  );
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t(isCreateMode ? "GoalDrawer.newGoal" : "GoalDrawer.editGoal") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("GoalDrawer.formFields.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_i = formik.touched.name && formik.errors.name) != null ? _i : " ",
        endAdornment: "none",
        requiredLabel: true,
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsx(
      RichTextField,
      {
        label: polyglot.t("GoalDrawer.formFields.description"),
        value: (_j = formik.values.description) != null ? _j : "",
        onChange: (value) => formik.setFieldValue("description", value),
        error: formik.touched.description && !!formik.errors.description,
        helperText: formik.touched.description && formik.errors.description
      }
    ),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_k = formik.values.dueDate) != null ? _k : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("dueDate", value);
          }
        },
        name: "dueDate",
        label: polyglot.t("GoalDrawer.formFields.dueDate"),
        error: !!formik.errors.dueDate && formik.touched.dueDate,
        helperText: formik.errors.dueDate && formik.touched.dueDate
      }
    ) }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "ownerType",
        label: polyglot.t("GoalDrawer.formFields.goalBelongsTo"),
        options: goalOwnerTypeOptions,
        value: formik.values.ownerType,
        onChange: (val) => updateOwnerType(val.target.value),
        compareValue: formik.values.ownerType,
        requiredLabel: true,
        error: !!formik.errors.ownerType && formik.touched.ownerType,
        helperText: formik.touched.ownerType && formik.errors.ownerType
      }
    ),
    formik.values.ownerType === GoalOwnerType.Individual && /* @__PURE__ */ jsx(
      MultiUserSelect,
      {
        id: "ownerIds",
        limitTags: 5,
        options: selectableUsers,
        value: multiUserSelectValue,
        onChange: (_, values) => {
          formik.setFieldValue(
            "ownerIds",
            values.map((v) => Number(v.value))
          );
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label,
        error: !!formik.errors.ownerIds && formik.touched.ownerIds,
        label: polyglot.t("GoalDrawer.formFields.select"),
        requiredLabel: true
      }
    ),
    formik.values.ownerType === GoalOwnerType.Department && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "departmentId",
        label: polyglot.t("GoalDrawer.formFields.select"),
        options: selectableDepartmentOptions,
        value: formik.values.departmentId,
        onChange: formik.handleChange,
        requiredLabel: true,
        compareValue: formik.values.departmentId,
        error: !!formik.errors.departmentId && formik.touched.departmentId,
        helperText: formik.touched.departmentId && formik.errors.departmentId
      }
    ),
    /* @__PURE__ */ jsx(
      MultipleSelectCheckbox,
      {
        id: "factorIds",
        label: polyglot.t("GoalDrawer.formFields.factors"),
        options: selectableGrowthFactors,
        value: growthFactorInputValue,
        onChange: (_, values) => {
          const growthFactorIds = values.map(({ value }) => value);
          formik.setFieldValue("factorIds", growthFactorIds, true);
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label,
        renderOptions: "chips",
        disableClearable: true
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "status",
        label: polyglot.t("GoalDrawer.formFields.status"),
        options: goalStatusOptions,
        value: formik.values.status,
        onChange: formik.handleChange,
        compareValue: formik.values.status,
        error: !!formik.errors.status && formik.touched.status,
        helperText: formik.touched.status && formik.errors.status
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t(
          isCreateMode ? "GoalDrawer.submitButtons.continueToKeyResults" : "GoalDrawer.submitButtons.save"
        ),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
