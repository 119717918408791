"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { forwardRef } from "react";
import {
  Autocomplete,
  Box,
  ListSubheader,
  Popper,
  Typography,
  autocompleteClasses,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { VariableSizeList } from "react-window";
import { ReactComponent as ArrowDownACIcon } from "@/images/fields/ArrowDown.svg";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const LISTBOX_PADDING = 8;
const iconSize = { width: "14px", height: "14px" };
function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = __spreadProps(__spreadValues({}, style), {
    top: style.top + LISTBOX_PADDING
  });
  if (dataSet.hasOwnProperty("group")) {
    return /* @__PURE__ */ jsx(ListSubheader, { component: "div", style: inlineStyle, children: dataSet.group }, dataSet.key);
  }
  const user = dataSet[1];
  const description = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q;
    if (((_a = user == null ? void 0 : user.role) == null ? void 0 : _a.jobTitle) && ((_c = (_b = user == null ? void 0 : user.role) == null ? void 0 : _b.jobTitle) == null ? void 0 : _c.length) > 0 && ((_e = (_d = user == null ? void 0 : user.role) == null ? void 0 : _d.site) == null ? void 0 : _e.name) && ((_h = (_g = (_f = user == null ? void 0 : user.role) == null ? void 0 : _f.site) == null ? void 0 : _g.name) == null ? void 0 : _h.length) > 0) {
      return [(_i = user == null ? void 0 : user.role) == null ? void 0 : _i.jobTitle, (_k = (_j = user == null ? void 0 : user.role) == null ? void 0 : _j.site) == null ? void 0 : _k.name].join(", ");
    } else {
      if (((_l = user == null ? void 0 : user.role) == null ? void 0 : _l.jobTitle) && ((_n = (_m = user == null ? void 0 : user.role) == null ? void 0 : _m.jobTitle) == null ? void 0 : _n.length) > 0) return (_o = user == null ? void 0 : user.role) == null ? void 0 : _o.jobTitle;
      else return (_q = (_p = user == null ? void 0 : user.role) == null ? void 0 : _p.site) == null ? void 0 : _q.name;
    }
  };
  return /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({ component: "li" }, dataSet[0]), { noWrap: true, style: inlineStyle, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "block", minHeight: 20, paddingY: spacing.p5 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
      user.value !== "public-org" && /* @__PURE__ */ jsx(UserAvatar, { userId: user.value, size: "xxsmall" }),
      /* @__PURE__ */ jsx(Box, { sx: { marginLeft: spacing.m10, minHeight: 20 }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: user.label }) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { marginLeft: spacing.m30, display: "flex", flexDirection: "column" }, children: /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.captionSmall), {
          marginTop: spacing.m5,
          color: themeColors.Grey,
          textTransform: "capitalize"
        }),
        children: description()
      }
    ) })
  ] }, user.value) }));
}
const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return /* @__PURE__ */ jsx("div", __spreadValues(__spreadValues({ ref }, props), outerProps));
});
function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}
const ListboxComponent = React.forwardRef(function ListboxComponent2(props, ref) {
  const _a = props, { children } = _a, other = __objRest(_a, ["children"]);
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...item.children || []);
  });
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 60 : 60;
  const getChildSize = (child) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }
    return itemSize;
  };
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };
  const gridRef = useResetCache(itemCount);
  return /* @__PURE__ */ jsx("div", { ref, children: /* @__PURE__ */ jsx(OuterElementContext.Provider, { value: other, children: /* @__PURE__ */ jsx(
    VariableSizeList,
    {
      itemData,
      height: getHeight() + 2 * LISTBOX_PADDING,
      width: "100%",
      ref: gridRef,
      outerElementType: OuterElementType,
      innerElementType: "ul",
      itemSize: (index) => getChildSize(itemData[index]),
      overscanCount: 5,
      itemCount,
      children: renderRow
    }
  ) }) });
});
const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0
    }
  }
});
export const MultiUserSelect = forwardRef(
  ({
    id,
    limitTags,
    options,
    value,
    onChange,
    isOptionEqualToValue,
    getOptionLabel,
    label,
    placeholder,
    error,
    helperText,
    required,
    requiredLabel
  }, ref) => {
    return /* @__PURE__ */ jsx(
      Autocomplete,
      {
        id,
        ref,
        multiple: true,
        fullWidth: true,
        limitTags,
        value,
        options,
        disableCloseOnSelect: true,
        onChange,
        isOptionEqualToValue,
        getOptionLabel,
        renderInput: (params) => /* @__PURE__ */ jsx(
          StyledAutoTextfield,
          __spreadProps(__spreadValues({}, params), {
            label: requiredLabel ? `${label} *` : label,
            fullWidth: true,
            placeholder,
            InputLabelProps: { shrink: true },
            variant: "standard",
            name: id,
            error,
            helperText,
            required
          })
        ),
        renderOption: (props, option, state) => {
          return [props, option, state.inputValue];
        },
        renderTags: (options2) => {
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 0.5 }, children: options2.map(
            (v) => v.value === "public-org" ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title3), { color: themeColors.DarkGrey }), children: "Everyone" }) : /* @__PURE__ */ jsx(UserAvatar, { userId: +v.value, tooltip: true, tooltipText: v.label, size: "xxsmall" }, v.value)
          ) });
        },
        ListboxComponent,
        PopperComponent: StyledPopper,
        popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, __spreadValues({}, iconSize)),
        clearIcon: /* @__PURE__ */ jsx(ClearIcon, __spreadValues({}, iconSize)),
        filterOptions: (options2, state) => {
          return options2.filter((o) => {
            var _a, _b;
            return (_b = o.label) == null ? void 0 : _b.toLowerCase().includes((_a = state.inputValue) == null ? void 0 : _a.toLowerCase());
          });
        }
      }
    );
  }
);
