"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { debounce, keyBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/fields/Dropdown.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SURVEYS_ME_INVITE_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { NotFoundBlock } from "@/v2/components/not-found-block.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { GrowthForbiddenAccess } from "@/v2/feature/growth/shared/components/growth-forbidden-access.component";
import { isHTMLValid } from "@/v2/feature/growth/shared/utils/growth-common.util";
import { SurveyAnswerAPI } from "@/v2/feature/growth/surveys/api-client/survey-answer.api";
import { SurveyEntryEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-entry.api";
import { MultipleChoiceQuestion } from "@/v2/feature/growth/surveys/features/survey-answer/survey-answer-submission/components/multiple-choice-question.component";
import { OpenEndedQuestion } from "@/v2/feature/growth/surveys/features/survey-answer/survey-answer-submission/components/open-ended-question.components";
import { ScaleQuestion } from "@/v2/feature/growth/surveys/features/survey-answer/survey-answer-submission/components/scale-question.component";
import { SingleAnswerQuestion } from "@/v2/feature/growth/surveys/features/survey-answer/survey-answer-submission/components/single-answer-question.component";
import { useProgress } from "@/v2/feature/growth/surveys/features/survey-answer/survey-answer-submission/hooks/use-progress.hook";
import { getSurveyDueDate } from "@/v2/feature/growth/surveys/features/survey-entry/survey-entry.util";
import {
  AnonymityType,
  DEFAULT_THRESHOLD_VALUE
} from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import {
  EntryState
} from "@/v2/feature/growth/surveys/interfaces/survey-entry.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { replaceParamsInQuestionText, stripHtml } from "@/v2/util/string.util";
export const SurveyAnswerSubmissionPage = () => {
  var _a, _b, _c;
  const params = useParams();
  const { entryId, cycleId } = params;
  const [answerArray, setAnswerArray] = useState(null);
  const [answers, setAnswers] = useState(void 0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const containerRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showError, setShowError] = useState(false);
  const { data: entryDetail, isValidating: loadingEntryDetail, error: entryError } = useApiClient(
    SurveyEntryEndpoints.getSurveyEntryDetail(entryId, cycleId),
    {
      suspense: false
    }
  );
  const getAnswersData = useCallback(() => __async(void 0, null, function* () {
    try {
      const response = yield SurveyAnswerAPI.getAnswer(entryId, cycleId);
      setAnswers(response);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  }), [entryId, cycleId, showMessage]);
  useEffect(() => {
    getAnswersData();
  }, [getAnswersData]);
  const checkForScroll = () => {
    if (containerRef.current) {
      const isContentOverflowing = containerRef.current.scrollHeight > window.innerHeight;
      setShowScrollButton(isContentOverflowing);
    }
  };
  const handleScrollClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth"
      });
      setShowScrollButton(false);
    }
  };
  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;
      setShowScrollButton(!isAtBottom);
    }
  };
  useEffect(() => {
    if (answers) {
      setAnswerArray(answers);
    }
  }, [answers]);
  useEffect(() => {
    setTimeout(() => checkForScroll(), 1500);
    window.addEventListener("resize", checkForScroll);
    return () => window.removeEventListener("resize", checkForScroll);
  }, []);
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  });
  const getAnonimityText = useMemo(() => {
    var _a2, _b2, _c2, _d, _e, _f, _g, _h;
    if (!Boolean((_b2 = (_a2 = entryDetail == null ? void 0 : entryDetail.cycle) == null ? void 0 : _a2.visibilitySettings) == null ? void 0 : _b2.anonymiseAnswers))
      return "No, your answers are not anonymous.";
    if (Boolean((_d = (_c2 = entryDetail == null ? void 0 : entryDetail.cycle) == null ? void 0 : _c2.visibilitySettings) == null ? void 0 : _d.anonymiseAnswers) && ((_f = (_e = entryDetail == null ? void 0 : entryDetail.cycle) == null ? void 0 : _e.visibilitySettings) == null ? void 0 : _f.anonymityType) && ((_h = (_g = entryDetail == null ? void 0 : entryDetail.cycle) == null ? void 0 : _g.visibilitySettings) == null ? void 0 : _h.anonymityType) === AnonymityType.Semi)
      return "It is semi-anonymous. Your department, site, gender and tenure are collected for segmenting";
    return "Yes";
  }, [(_a = entryDetail == null ? void 0 : entryDetail.cycle) == null ? void 0 : _a.visibilitySettings]);
  let cycle;
  let sections;
  let questions;
  let entry;
  if (entryDetail) {
    ({ cycle, sections, questions, entry } = entryDetail);
  }
  const questionsLookup = useMemo(() => keyBy(questions != null ? questions : [], "id"), [questions]);
  const progressData = useProgress(entryDetail == null ? void 0 : entryDetail.questions, answerArray);
  const isSubmitable = useMemo(() => {
    if (!questions || !answerArray) {
      return false;
    }
    const allQuestionsAnswered = questions.every((q) => {
      var _a2, _b2;
      const answer = answerArray.find((a) => a.questionId === q.id);
      if (!answer) return false;
      if (q.type === "openEnded") {
        return answer && answer.comment && isHTMLValid(answer.comment);
      } else {
        return answer && answer.answer && ((_b2 = (_a2 = answer.answer) == null ? void 0 : _a2.trim()) == null ? void 0 : _b2.length) > 0;
      }
    });
    const allCommentsValid = questions.every((question) => {
      if (question.isCommentRequired) {
        const answer = answerArray.find((a) => a.questionId === question.id);
        return answer && answer.comment && isHTMLValid(answer.comment);
      }
      return true;
    });
    return allQuestionsAnswered && allCommentsValid;
  }, [answerArray, questions]);
  const debouncedAutoSave = useMemo(
    () => debounce(
      (answersToSave, entryId2, cycleId2, entry2) => __async(void 0, null, function* () {
        if (!answersToSave || answersToSave.length === 0 || !entry2) return;
        try {
          if ((entryDetail == null ? void 0 : entryDetail.entry.entryState) === EntryState.Submitted && isSubmitable && answersToSave && entry2) {
            yield SurveyAnswerAPI.submitAnswers(answersToSave, entryId2, cycleId2, entry2.participantId);
          } else if ((entryDetail == null ? void 0 : entryDetail.entry.entryState) !== EntryState.Submitted) {
            yield SurveyAnswerAPI.saveAnswersAsDraft(answersToSave, entryId2, cycleId2, entry2.participantId);
          }
        } catch (error) {
          showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
        }
      }),
      2e3,
      { leading: false, trailing: true }
    ),
    [entryDetail, isSubmitable, showMessage]
  );
  useEffect(() => {
    var _a2, _b2;
    if (answerArray && !Boolean((_b2 = (_a2 = entryDetail == null ? void 0 : entryDetail.cycle) == null ? void 0 : _a2.visibilitySettings) == null ? void 0 : _b2.anonymiseAnswers)) {
      debouncedAutoSave(answerArray, entryId, cycleId, entry);
    }
    return () => {
      debouncedAutoSave.cancel();
    };
  }, [answerArray, entryId, cycleId, entry, entryDetail, debouncedAutoSave]);
  const handleAnswerChange = (questionId, updateObject, entry2) => {
    setAnswerArray((prevAnswers) => {
      const updatedAnswers = (prevAnswers == null ? void 0 : prevAnswers.map((answer) => {
        if (answer.questionId === questionId) {
          return __spreadProps(__spreadValues({}, answer), { answer: updateObject.updatedAnswer, comment: updateObject.comment });
        }
        return answer;
      })) || [];
      if (!(prevAnswers == null ? void 0 : prevAnswers.some((answer) => answer.questionId === questionId))) {
        updatedAnswers.push({
          id: v4(),
          cycleId,
          entryId,
          participantId: entry2.participantId,
          comment: updateObject.comment,
          answer: updateObject.updatedAnswer,
          questionId
        });
      }
      return updatedAnswers;
    });
  };
  const handleSaveDraft = () => __async(void 0, null, function* () {
    try {
      const currentSurveyEntry = entryDetail == null ? void 0 : entryDetail.entry;
      if (answerArray && currentSurveyEntry) {
        debouncedAutoSave.cancel();
        yield SurveyAnswerAPI.saveAnswersAsDraft(answerArray, entryId, cycleId, currentSurveyEntry.participantId);
        yield getAnswersData();
        showMessage("Successfully saved as draft", "success");
      }
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  });
  const handleSubmit = () => __async(void 0, null, function* () {
    try {
      if (isSubmitable && answerArray && entry) {
        setIsSubmitting(true);
        yield SurveyAnswerAPI.submitAnswers(answerArray, entryId, cycleId, entry == null ? void 0 : entry.participantId);
        routerHistory.push(SURVEYS_ME_INVITE_ROUTE);
        showMessage("Successfully submitted the answers", "success");
      } else {
        setShowError(true);
        showMessage("Please answer all the questions before submitting", "info");
      }
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsSubmitting(false);
    }
  });
  const isAnonymousSurvey = (_b = cycle == null ? void 0 : cycle.visibilitySettings) == null ? void 0 : _b.anonymiseAnswers;
  if (!entryDetail && entryError && entryError.status === 403)
    return /* @__PURE__ */ jsx(Box, { sx: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(GrowthForbiddenAccess, {}) });
  if (!entry && !loadingEntryDetail)
    return /* @__PURE__ */ jsx(Box, { sx: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(NotFoundBlock, { title: "Oops", subtitle: "Entry details not found" }) });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: entryDetail == null ? void 0 : entryDetail.cycle.displayName }),
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: [
          !isAnonymousSurvey && (entryDetail == null ? void 0 : entryDetail.entry.entryState) !== EntryState.Submitted && /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "secondary", sizeVariant: "small", onClick: () => handleSaveDraft(), children: "Save" }),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "primary",
              sizeVariant: "small",
              onClick: () => handleSubmit(),
              loading: isSubmitting,
              children: "Submit"
            }
          )
        ] }),
        showAction: true,
        sx: { maxWidth: "600px", width: "100%", margin: "0 auto" },
        showBack: true,
        backPath: SURVEYS_ME_INVITE_ROUTE
      }
    ),
    /* @__PURE__ */ jsx(
      ContentWrapper,
      {
        loading: loadingEntryDetail,
        ref: containerRef,
        sx: {
          overflowY: "auto",
          maxHeight: "calc(100vh - 120px)"
        },
        children: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              overflowY: "hidden",
              flexDirection: "column",
              gap: spacing.g16,
              maxWidth: "600px",
              width: "100%",
              margin: "0 auto"
            },
            children: [
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
                entry && cycle && /* @__PURE__ */ jsx(
                  ViewItem,
                  {
                    label: "Due date",
                    value: getSurveyDueDate(__spreadProps(__spreadValues({}, entry), { cycle })).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" })
                  }
                ),
                /* @__PURE__ */ jsx(ViewItem, { label: "Is this survey anonymous?", value: getAnonimityText }),
                isAnonymousSurvey && /* @__PURE__ */ jsx(
                  ViewItem,
                  {
                    label: "Anonymity threshold",
                    value: `${((_c = cycle == null ? void 0 : cycle.visibilitySettings) == null ? void 0 : _c.anonymityThreshold) || DEFAULT_THRESHOLD_VALUE}`
                  }
                )
              ] }),
              cycle == null ? void 0 : cycle.order.map((item) => /* @__PURE__ */ jsx(
                RenderQuestionOrSection,
                {
                  item,
                  questionsLookup,
                  handleAnswerChange,
                  sections,
                  cycle,
                  entry,
                  answerArray,
                  showError
                },
                item.id
              )),
              /* @__PURE__ */ jsx(StyledTooltip, { title: `${(progressData == null ? void 0 : progressData.progress) || 0}% done`, children: /* @__PURE__ */ jsx(
                CircularProgress,
                {
                  value: (progressData == null ? void 0 : progressData.progress) || 0,
                  size: 35,
                  color: "success",
                  thickness: 6,
                  title: `${(progressData == null ? void 0 : progressData.progress) || 0}`,
                  variant: "determinate",
                  sx: {
                    position: "fixed",
                    top: "20px",
                    right: "20px",
                    textAlign: "center",
                    color: themeColors.ZeltYellow,
                    borderRadius: "50%",
                    boxShadow: "inset 0 0 0px 4.5px #F6F3EF",
                    backgroundColor: "transparent"
                  }
                }
              ) }),
              showScrollButton && /* @__PURE__ */ jsx(
                IconButton,
                {
                  colorVariant: "primary",
                  sizeVariant: "small",
                  onClick: handleScrollClick,
                  style: { position: "fixed", bottom: "20px", right: "20px", textAlign: "center" },
                  children: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize))
                }
              )
            ]
          }
        )
      }
    )
  ] });
};
const RenderQuestionOrSection = ({
  item,
  questionsLookup,
  handleAnswerChange,
  sections,
  cycle,
  entry,
  answerArray,
  showError
}) => {
  const renderQuestion = (questionId) => {
    const question = questionsLookup && questionsLookup[questionId];
    if (!question || !cycle) return null;
    return /* @__PURE__ */ jsx(
      QuestionComponent,
      {
        question,
        answerArray,
        handleAnswerChange,
        entry,
        showError
      },
      question.id
    );
  };
  const renderSection = (sectionId) => {
    var _a;
    const section = sections == null ? void 0 : sections.find((s) => s.id === sectionId);
    if (!section || !cycle) return null;
    const orderSection = cycle == null ? void 0 : cycle.order.find((o) => o.id === sectionId && o.type === "section");
    if (!orderSection) return null;
    const orderedQuestions = ((_a = orderSection.questions) != null ? _a : []).map((questionId) => questionsLookup[questionId]).filter((question) => Boolean(question));
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g16 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: section.name }),
      orderedQuestions.map((q) => /* @__PURE__ */ jsx(
        QuestionComponent,
        {
          question: q,
          answerArray,
          handleAnswerChange,
          entry,
          showError
        },
        q.id
      ))
    ] });
  };
  const renderQuestionOrSection = () => {
    var _a;
    if (item.type === "question" && questionsLookup[item.id]) {
      return renderQuestion(item.id);
    } else if (item.type === "section" && ((_a = item.questions) != null ? _a : []).some((id) => questionsLookup.hasOwnProperty(id))) {
      return /* @__PURE__ */ jsx(Box, { sx: { marginTop: spacing.m48 }, children: renderSection(item.id) });
    } else return /* @__PURE__ */ jsx(Fragment, {});
  };
  return /* @__PURE__ */ jsx(Fragment, { children: renderQuestionOrSection() });
};
const QuestionComponent = ({
  question,
  answerArray,
  handleAnswerChange,
  entry,
  showError
}) => {
  var _a;
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const questionText = useMemo(() => {
    var _a2, _b, _c;
    if (!question) return "";
    const company_name = (_b = (_a2 = user == null ? void 0 : user.company) == null ? void 0 : _a2.name) != null ? _b : void 0;
    const cleanedQuestionText = (_c = replaceParamsInQuestionText(stripHtml(question.questionText), { company_name })) != null ? _c : "";
    return cleanedQuestionText;
  }, [question, (_a = user == null ? void 0 : user.company) == null ? void 0 : _a.name]);
  const getQuestionByType = (question2) => {
    switch (question2.type) {
      case QuestionType.SingleAnswer:
        return /* @__PURE__ */ jsx(
          SingleAnswerQuestion,
          {
            question: question2,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      case QuestionType.OpenEnded:
        return /* @__PURE__ */ jsx(
          OpenEndedQuestion,
          {
            question: question2,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      case QuestionType.MultipleAnswer:
        return /* @__PURE__ */ jsx(
          MultipleChoiceQuestion,
          {
            question: question2,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      case QuestionType.ScaleQuestion:
        return /* @__PURE__ */ jsx(
          ScaleQuestion,
          {
            question: question2,
            answerArray,
            handleAnswerChange,
            entry,
            showError
          }
        );
      default:
        return null;
    }
  };
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.s2,
        padding: spacing.p16,
        border: borders.background,
        borderRadius: radius.br8
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", dangerouslySetInnerHTML: { __html: questionText != null ? questionText : "" } }),
        getQuestionByType(question)
      ]
    }
  );
};
const ViewItem = ({ label, value }) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "inline-grid",
        gridTemplateColumns: "2fr 2fr",
        rowGap: spacing.g24,
        columnGap: spacing.g4,
        width: "100%"
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: label }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: value })
      ]
    }
  );
};
