"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { EmptyStateBox } from "@v2/components/empty-state-box.component";
import { generatePath, Redirect } from "react-router-dom";
import { useGoalsReach } from "../hooks/use-goals-reach.hook";
import { GROWTH_COMPANY_GOAL_ROUTE, GROWTH_ME_GOAL_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { GoalListItem } from "@/v2/feature/growth/goals/components/goal-list-item.component";
import { GoalMutationModal } from "@/v2/feature/growth/goals/components/goal-mutation-modal.component";
import { GoalStatus } from "@/v2/feature/growth/goals/goal.interface";
import { GoalEndpoints } from "@/v2/feature/growth/goals/goals.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GoalsListPage = () => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("");
  const reach = useGoalsReach();
  const [redirectToGoalId, setRedirectToGoalId] = useState(null);
  const { data: goals, mutate: refreshGoals, isLoading: loadingGoals } = useApiClient(
    GoalEndpoints.getGoals(reach, searchInput, filterString),
    {
      suspense: false
    }
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [clientSearchInput, setClientSearchInput] = useState("");
  useEffect(() => {
    const timerId = setTimeout(() => {
      setSearchInput(clientSearchInput);
    }, 300);
    return () => {
      clearTimeout(timerId);
    };
  }, [clientSearchInput, setSearchInput]);
  if (redirectToGoalId) {
    return /* @__PURE__ */ jsx(
      Redirect,
      {
        to: generatePath(reach === ReachType.Company ? GROWTH_COMPANY_GOAL_ROUTE : GROWTH_ME_GOAL_ROUTE, {
          goalId: redirectToGoalId
        })
      }
    );
  }
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("GoalsPage.goals") }),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsDrawerOpen(true), children: polyglot.t("GoalsPage.newGoal") }),
        showAction: Boolean(Array.isArray(goals) && goals.length > 0),
        sx: { padding: "0 1.5rem", display: "flex", justifyContent: "space-between", width: "100%" }
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, noHorizontalPadding: true, children: [
      !loadingGoals && (!goals || goals && goals.length === 0) && !searchInput && !filterString ? /* @__PURE__ */ jsx(
        EmptyStateBox,
        {
          onClick: () => setIsDrawerOpen(true),
          title: polyglot.t("GoalsPage.createFirstGoal.title"),
          subtitle: polyglot.t("GoalsPage.createFirstGoal.subtitle"),
          buttonTitle: polyglot.t("GoalsPage.createFirstGoal.buttonTitle"),
          style: { marginLeft: spacing.m20 }
        }
      ) : /* @__PURE__ */ jsx(
        GoalsList,
        {
          goals: goals != null ? goals : [],
          reach,
          searchInput: clientSearchInput,
          setSearchInput: setClientSearchInput,
          filterString,
          setFilterString
        }
      ),
      /* @__PURE__ */ jsx(
        GoalMutationModal,
        {
          mode: "create",
          isOpen: isDrawerOpen,
          setIsOpen: setIsDrawerOpen,
          onClose: () => setIsDrawerOpen(false),
          refresh: (goalId) => __async(void 0, null, function* () {
            setRedirectToGoalId(goalId);
            yield refreshGoals == null ? void 0 : refreshGoals();
          })
        }
      )
    ] })
  ] });
};
const GoalsList = ({
  goals,
  searchInput,
  setSearchInput,
  filterString,
  setFilterString
}) => {
  const { polyglot } = usePolyglot();
  const GoalStatusFilter = useMemo(
    () => [
      { label: polyglot.t("GoalsPage.filters.planned"), value: GoalStatus.Planned },
      { label: polyglot.t("GoalsPage.filters.active"), value: GoalStatus.Active },
      { label: polyglot.t("GoalsPage.filters.achieved"), value: GoalStatus.Achieved }
    ],
    [polyglot]
  );
  const renderedGoals = useMemo(() => {
    const searchFilterGoals = (goal) => {
      if (!searchInput || searchInput.length === 0) return true;
      return goal.name.toLowerCase().includes(searchInput.toLowerCase());
    };
    return goals == null ? void 0 : goals.filter(searchFilterGoals).map((goal) => /* @__PURE__ */ jsx(GoalListItem, { goal, showDescription: false }, goal.id));
  }, [goals, searchInput]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          alignItems: "center",
          gap: spacing.s1,
          paddingLeft: spacing.p16,
          paddingRight: spacing.p16,
          paddingBottom: spacing.p16
        },
        children: [
          /* @__PURE__ */ jsx(
            TableSearch,
            {
              query: searchInput,
              handleChange: (e) => {
                setSearchInput(e.target.value);
              },
              debounceSearchWait: 100,
              style: {
                width: "347px"
              }
            }
          ),
          /* @__PURE__ */ jsx(
            CategoryFilters,
            {
              filterTypes: {
                status: GoalStatusFilter
              },
              setFilterString,
              filterString
            }
          )
        ]
      }
    ),
    renderedGoals
  ] });
};
